import { io } from "socket.io-client";
import Header from "./components/header/header";
import Sidebar from "./components/sidebar/sidebar";
import Footer from "./components/footer";
import AuthProvider from "../src/context/AuthProvider";
import Router from "./routes/router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const socket = io(process.env.REACT_APP_API_PROD_VIEW);

function App() {
  return (
    <AuthProvider>
      <Header />
      <Sidebar />
      <div style={{ marginTop: "57px" }}>
        <Router />
      </div>

      <Footer />
    </AuthProvider>
  );
}

export default App;
