import { createContext, useState } from "react";
import Login from "../pages/login/login";

export const UserContext = createContext(null);
export const NotificationContext = createContext(null);
function AuthProvider({ children }) {
  const [notifikasi, setNotifikasi] = useState({
    pesan: 0,
    meeting: 0,
    project: 0,
    ide: 0,
    agenda: 0,
    memo: 0,
  });
  const user = sessionStorage.getItem("auth")
    ? JSON.parse(atob(sessionStorage.getItem("auth")))
    : null;

  if (user) {
    return (
      <UserContext.Provider value={{ user }}>
        <NotificationContext.Provider value={{ notifikasi, setNotifikasi }}>
          {children}
        </NotificationContext.Provider>
      </UserContext.Provider>
    );
  } else {
    return <Login />;
  }
}

export default AuthProvider;
