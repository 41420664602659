import { Routes, Route } from "react-router-dom";
import { lazy } from "react";
import { Suspense } from "react";
import Loader from "../components/loader";

const Project = lazy(() => import("../pages/project/index"));
const DetailProject = lazy(() => import("../pages/project/detailProject"));

const Agenda = lazy(() => import("../pages/agenda/index"));

const Dashboard = lazy(() => import("../pages/dashboard"));

const Home = lazy(() => import("../pages/home/index"));

const Login = lazy(() => import("../pages/login/login"));

const Meeting = lazy(() => import("../pages/meeting/index"));
const DetailMeeting = lazy(() => import("../pages/meeting/detailMeeting"));

const Message = lazy(() => import("../pages/message/index"));
const StarredMessages = lazy(() => import("../pages/message/starredMessages"));
const SendMessages = lazy(() => import("../pages/message/sendMessages"));
const DraftPesan = lazy(() => import("../pages/message/draftMessages"));
const ArchiveMessages = lazy(() => import("../pages/message/archiveMessages"));
const TrashMessages = lazy(() => import("../pages/message/trashMessages"));
const NewMessage = lazy(() => import("../pages/message/createMessages"));
const InboxMessages = lazy(() => import("../pages/message/inboxMessages"));
const DetailsMessages = lazy(() => import("../pages/message/detailsMessages"));
const EditPesan = lazy(() => import("../pages/message/editPesan"));

const Ide = lazy(() => import("../pages/idea"));
const ArchiveIdea = lazy(() => import("../pages/idea/archiveIdea"));
const SharedIdea = lazy(() => import("../pages/idea/sharedIdea.js"));
const StarredIdea = lazy(() => import("../pages/idea/starredIdea.js"));
const CreateIdea = lazy(() => import("../pages/idea/createIdea.js"));
const TrashIdea = lazy(() => import("../pages/idea/trashIdea.js"));
const MyIdea = lazy(() => import("../pages/idea/myIdea"));
const DetailIde = lazy(() => import("../pages/idea/detailIde"));

const CreatorMemo = lazy(() => import("../pages/memo/creator/index"));
const RequestMemo = lazy(() => import("../pages/memo/creator/requestMemo.js"));
const StarredMemo = lazy(() => import("../pages/memo/creator/starredMemo.js"));
const SendMemo = lazy(() => import("../pages/memo/creator/sendMemo.js"));
const ArchiveMemo = lazy(() => import("../pages/memo/creator/archiveMemo.js"));
const TrashMemo = lazy(() => import("../pages/memo/creator/trashMemo.js"));
const CreateMemo = lazy(() => import("../pages/memo/creator/createMemo.js"));
const DetailMemoCreator = lazy(() =>
  import("../pages/memo/creator/detailMemoCreator")
);
const DisetujuiMemo = lazy(() =>
  import("../pages/memo/creator/approvedMemo.js")
);

const AprovalMemo = lazy(() => import("../pages/memo/aproval/index"));
const PersetujuanMemo = lazy(() =>
  import("../pages/memo/aproval/approvalMemo.js")
);
const DetailMemoAproval = lazy(() =>
  import("../pages/memo/aproval/detailMemoAproval")
);
const AprovedMemoApproval = lazy(() =>
  import("../pages/memo/aproval/approvedMemo.js")
);
const StarredMemoAproval = lazy(() =>
  import("../pages/memo/aproval/starredMemo.js")
);
const ArchiveMemoAproval = lazy(() =>
  import("../pages/memo/aproval/archiveMemo.js")
);

const Dokumentasi = lazy(() => import("../pages/dokumentasi/index"));

const Partnership = lazy(() => import("../pages/partnership/index"));

const Ruangkantor = lazy(() => import("../pages/kantor/index"));

function Router() {
  const user = sessionStorage.getItem("auth")
    ? JSON.parse(atob(sessionStorage.getItem("auth")))
    : null;

  const routeMemo = () => {
    if (user.levele === "Admin")
      return (
        <Route
          path="/memo"
          Component={() => (
            <Suspense
              fallback={
                <Loader
                  style={{
                    position: "fixed",
                    top: 0,
                    zIndex: 100000,
                    height: "100vh",
                    backgroundColor: "#d8e6cf",
                  }}
                  className="w-100"
                />
              }
            >
              <AprovalMemo />
            </Suspense>
          )}
        >
          <Route
            path="request"
            Component={() => (
              <Suspense>
                <PersetujuanMemo />
              </Suspense>
            )}
          />
          <Route
            path="request/:id"
            Component={() => (
              <Suspense>
                <DetailMemoAproval type="memo" />
              </Suspense>
            )}
          />
          <Route
            path="approved"
            Component={() => (
              <Suspense>
                <AprovedMemoApproval />
              </Suspense>
            )}
          />
          <Route
            path="approved/:id"
            Component={() => (
              <Suspense>
                <DetailMemoAproval type="arsip" />
              </Suspense>
            )}
          />
          <Route
            path="starred"
            Component={() => (
              <Suspense>
                <StarredMemoAproval />
              </Suspense>
            )}
          />
          <Route
            path="starred/:id"
            Component={() => (
              <Suspense>
                <DetailMemoAproval type="memo" />
              </Suspense>
            )}
          />
          <Route
            path="archive"
            Component={() => (
              <Suspense>
                <ArchiveMemoAproval />
              </Suspense>
            )}
          />
          <Route
            path="archive/:id"
            Component={() => (
              <Suspense>
                <DetailMemoAproval type="arsip" />
              </Suspense>
            )}
          />
        </Route>
      );
    else
      return (
        <Route
          path="/memo"
          Component={() => (
            <Suspense
              fallback={
                <Loader
                  style={{
                    position: "fixed",
                    top: 0,
                    zIndex: 100000,
                    height: "100vh",
                    backgroundColor: "#d8e6cf",
                  }}
                  className="w-100"
                />
              }
            >
              <CreatorMemo />
            </Suspense>
          )}
        >
          <Route
            path="request"
            Component={() => (
              <Suspense>
                <RequestMemo />
              </Suspense>
            )}
          />
          <Route
            path="request/:id"
            Component={() => (
              <Suspense>
                <DetailMemoCreator type="memo" />
              </Suspense>
            )}
          />
          <Route
            path="starred"
            Component={() => (
              <Suspense>
                <StarredMemo />
              </Suspense>
            )}
          />
          <Route
            path="starred/:id"
            Component={() => (
              <Suspense>
                <DetailMemoCreator type="memo" />
              </Suspense>
            )}
          />
          <Route
            path="approved"
            Component={() => (
              <Suspense>
                <DisetujuiMemo />
              </Suspense>
            )}
          />
          <Route
            path="approved/:id"
            Component={() => (
              <Suspense>
                <DetailMemoCreator type="arsip" />
              </Suspense>
            )}
          />
          <Route
            path="send"
            Component={() => (
              <Suspense>
                <SendMemo />
              </Suspense>
            )}
          />
          <Route
            path="send/:id"
            Component={() => (
              <Suspense>
                <DetailMemoCreator type="arsip" />
              </Suspense>
            )}
          />
          <Route
            path="archive"
            Component={() => (
              <Suspense>
                <ArchiveMemo />
              </Suspense>
            )}
          />
          <Route
            path="archive/:id"
            Component={() => (
              <Suspense>
                <DetailMemoCreator type="arsip" />
              </Suspense>
            )}
          />
          <Route
            path="trash"
            Component={() => (
              <Suspense>
                <TrashMemo />
              </Suspense>
            )}
          />
          <Route
            path="trash/:id"
            Component={() => (
              <Suspense>
                <DetailMemoCreator type="memo" />
              </Suspense>
            )}
          />
          <Route
            path="creatememo"
            Component={() => (
              <Suspense>
                <CreateMemo />
              </Suspense>
            )}
          />
        </Route>
      );
  };

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Suspense
            fallback={
              <Loader
                style={{
                  position: "fixed",
                  top: 0,
                  zIndex: 100000,
                  height: "100vh",
                  backgroundColor: "#d8e6cf",
                }}
                className="w-100"
              />
            }
          >
            <Dashboard />
          </Suspense>
        }
      />
      <Route
        exact
        path="/login"
        name="Login Page"
        render={(props) => (
          <Suspense>
            <Login {...props} />
          </Suspense>
        )}
      />

      <Route
        exact
        path="/home"
        name="Home Page"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Home />
          </Suspense>
        )}
      />

      <Route
        path="/idea"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Ide />
          </Suspense>
        )}
      >
        <Route
          path="myidea"
          Component={() => (
            <Suspense>
              <MyIdea />
            </Suspense>
          )}
        />
        <Route
          path="myidea/:id"
          Component={() => (
            <Suspense>
              <DetailIde type="ruangIde" />
            </Suspense>
          )}
        />

        <Route
          path="starred"
          Component={() => (
            <Suspense>
              <StarredIdea />
            </Suspense>
          )}
        />
        <Route
          path="starred/:id"
          Component={() => (
            <Suspense>
              <DetailIde type="berbintang" />
            </Suspense>
          )}
        />
        <Route
          path="sharedideas"
          Component={() => (
            <Suspense>
              <SharedIdea />
            </Suspense>
          )}
        />
        <Route
          path="sharedideas/:id"
          Component={() => (
            <Suspense>
              <DetailIde type="berbagiIde" />
            </Suspense>
          )}
        />
        <Route
          path="archive"
          Component={() => (
            <Suspense>
              <ArchiveIdea />
            </Suspense>
          )}
        />
        <Route
          path="archive/:id"
          Component={() => (
            <Suspense>
              <DetailIde type="arsip" />
            </Suspense>
          )}
        />
        <Route
          path="trash"
          Component={() => (
            <Suspense>
              <TrashIdea />
            </Suspense>
          )}
        />
        <Route
          path="trash/:id"
          Component={() => (
            <Suspense>
              <DetailIde type="sampah" />
            </Suspense>
          )}
        />
        <Route
          path="createidea"
          Component={() => (
            <Suspense>
              <CreateIdea />
            </Suspense>
          )}
        />
      </Route>

      <Route
        path="/project"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Project />
          </Suspense>
        )}
      />
      <Route
        path="project/:id"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <DetailProject />
          </Suspense>
        )}
      />

      <Route
        path="/agenda"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Agenda />
          </Suspense>
        )}
      />

      <Route
        path="/meeting"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Meeting />
          </Suspense>
        )}
      />
      <Route
        path="meeting/:id"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <DetailMeeting />
          </Suspense>
        )}
      />

      {routeMemo()}
      <Route
        path="/message"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Message />
          </Suspense>
        )}
      >
        <Route
          path="inbox"
          Component={() => (
            <Suspense>
              <InboxMessages />
            </Suspense>
          )}
        />
        <Route
          path="inbox/message/:id"
          Component={() => (
            <Suspense>
              <DetailsMessages type="kontakmasuk" />
            </Suspense>
          )}
        />
        <Route
          path="inbox/memo/:id"
          Component={() => (
            <Suspense>
              <DetailMemoCreator type="arsip" />
            </Suspense>
          )}
        />
        <Route
          path="starred"
          Component={() => (
            <Suspense>
              <StarredMessages />
            </Suspense>
          )}
        />
        <Route
          path="starred/:id"
          Component={() => (
            <Suspense>
              <DetailsMessages type="berbintang" />
            </Suspense>
          )}
        />
        <Route
          path="send"
          Component={() => (
            <Suspense>
              <SendMessages />
            </Suspense>
          )}
        />
        <Route
          path="send/:id"
          Component={() => (
            <Suspense>
              <DetailsMessages type="terkirim" />
            </Suspense>
          )}
        />
        <Route
          path="draft"
          Component={() => (
            <Suspense>
              <DraftPesan />
            </Suspense>
          )}
        />
        <Route
          path="draft/:id"
          Component={() => (
            <Suspense>
              <EditPesan />
            </Suspense>
          )}
        />
        <Route
          path="archive"
          Component={() => (
            <Suspense>
              <ArchiveMessages />
            </Suspense>
          )}
        />
        <Route
          path="archive/:id"
          Component={() => (
            <Suspense>
              <DetailsMessages type="arsip" />
            </Suspense>
          )}
        />
        <Route
          path="trash"
          Component={() => (
            <Suspense>
              <TrashMessages />
            </Suspense>
          )}
        />
        <Route
          path="trash/:id"
          Component={() => (
            <Suspense>
              <DetailsMessages type="sampah" />
            </Suspense>
          )}
        />
        <Route
          path="createmessage"
          Component={() => (
            <Suspense>
              <NewMessage />
            </Suspense>
          )}
        />
      </Route>
      <Route
        path="document"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Dokumentasi />
          </Suspense>
        )}
      ></Route>
      <Route
        path="/partnership"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Partnership />
          </Suspense>
        )}
      ></Route>
      <Route
        path="/office"
        Component={() => (
          <Suspense
            fallback={
              <Loader
                style={{ height: "88vh", backgroundColor: "#d8e6cf" }}
                className="w-100"
              />
            }
          >
            <Ruangkantor />
          </Suspense>
        )}
      />
    </Routes>
  );
}

export default Router;
