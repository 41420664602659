import React from "react";

const Loader = ({ className, style }) => {
  return (
    <div
      style={style}
      className={`d-flex flex-column align-items-center justify-content-center ${className}`}
    >
      <i className="fas fa-3x fa-sync-alt fa-spin" />
    </div>
  );
};

export default Loader;
