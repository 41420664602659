const sidebarMenu = [
  {
    id: 0,
    title: "Home",
    to: "/home",
    icon: "nav-icon fas fa-home",
    newTab: false,
  },
  {
    id: 1,
    title: "Office",
    to: "/office",
    icon: "nav-icon fas fa-city",
    newTab: false,
  },
  {
    id: 2,
    title: "Partner",
    to: "/partnership",
    icon: "nav-icon fas fa-handshake",
    newTab: false,
  },
  {
    id: 2,
    title: "Document",
    to: "/document",
    icon: "nav-icon fas fa-file",
    newTab: false,
  },
  // {
  //   id: 3,
  //   title: "HRIS",
  //   to: "/hris",
  //   icon: "nav-icon fas fa-",
  //   newTab: false,
  // },
  // {
  //   id: 4,
  //   title: "EPMS",
  //   to: "/epms",
  //   icon: "nav-icon fas fa",
  //   newTab: false,
  // },
  // {
  //   id: 5,
  //   title: "EDMS",
  //   to: "/edms",
  //   icon: "nav-icon fas fa-",
  //   newTab: false,
  // },
  // {
  //   id: 6,
  //   title: "CRM",
  //   to: "/crm",
  //   icon: "nav-icon fas fa",
  //   newTab: false,
  // },
  // {
  //   id: 7,
  //   title: "Supply Chain",
  //   to: "/shupply",
  //   icon: "nav-icon fas fa-",
  //   newTab: false,
  // },
  // {
  //   id: 8,
  //   title: "E-Filling",
  //   to: "/shuppefillingly",
  //   icon: "nav-icon fas fa",
  //   newTab: false,
  // },
  //   {
  //     id: 7,
  //     title: "Setting",
  //     to: "/setting",
  //     newTab: false,
  //   },
];
export default sidebarMenu;
