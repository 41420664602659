const menuData = [
  {
    id: 1,
    title: "Idea",
    to: "/idea/myidea",
    newTab: false,
    notif: "ide",
  },
  {
    id: 2,
    title: "Project",
    to: "/project",
    newTab: false,
    notif: "project",
  },
  {
    id: 3,
    title: "Agenda",
    to: "/agenda",
    newTab: false,
    notif: "agenda",
  },
  {
    id: 4,
    title: "Meeting",
    to: "/meeting",
    newTab: false,
    notif: "meeting",
  },
  {
    id: 5,
    title: "Memo",
    to: "/memo/request",
    newTab: false,
    notif: "memo",
  },
  {
    id: 6,
    title: "Message",
    to: "/message/inbox",
    newTab: false,
    notif: "pesan",
  },
];
export default menuData;
