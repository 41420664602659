import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import datasidebar from "../../data/datasidebar";
import styles from "./sidebar.module.css";

const Sidebar = ({ setModal }) => {
  const location = useLocation();

  const user = sessionStorage.getItem("auth")
    ? JSON.parse(atob(sessionStorage.getItem("auth")))
    : null;
  const [redirectOut, setRedirectOut] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const onLogout = (event) => {
    event.preventDefault();

    sessionStorage.removeItem("auth");
    window.location.href = "/";
  };

  if (redirectOut === true) {
    window.location.href = "/";
  }

  useEffect(() => {
    if (location.pathname.includes("/home")) setActiveTab("Home");
    else if (location.pathname.includes("/office")) setActiveTab("Office");
    else if (location.pathname.includes("/partnership"))
      setActiveTab("Partner");
    else if (location.pathname.includes("/document")) setActiveTab("Document");
    else setActiveTab("");
  }, [location.pathname]);
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4">
      <a href="/home" className="brand-link d-flex align-items-center">
        <img
          src="/img/logo/logoEthosWorld.png"
          alt="Ethos World"
          className="brand-image img-circle"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text nama">Ethos World</span>
      </a>

      <div className="sidebar">
        <div className="user-panel py-4 d-flex align-items-center">
          <div className="image">
            <img
              src="/img/logo/person-icon.png"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info user">
            <p className="text-wrap text-break text-left m-0 p-0 d-block namapeg">
              {user?.fullname ?? "User"}
            </p>
          </div>
        </div>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item navbars"></li>
            {datasidebar.map((menuItem, idx) => (
              <li
                key={idx}
                className={`${
                  activeTab === menuItem.title ? styles["active"] : ""
                } nav-item navbars`}
              >
                <Link
                  to={menuItem.to}
                  className="nav-link"
                  data-widget="pushmenu"
                >
                  <i className={menuItem.icon} />
                  <p>{menuItem.title}</p>
                </Link>
              </li>
            ))}
            <li className="nav-item navbars  d-md-none d-lg-none d-xl-none ml-auto">
              <Link
                to=""
                onClick={(event) => onLogout(event)}
                className="nav-link"
                data-widget="pushmenu"
              >
                <i className="nav-icon fas fa-sign-out-alt" />
                <p>Logout</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
